import { GetServerSidePropsContext } from 'next'
import { Layout, SliceZone } from 'components'
import { fetchAPI } from 'utils/prismic-configuration'
import { useEffect } from 'react'
import GetPagePaths from 'queries/GetPagePaths.gql'
import {
  Case_StudyConnectionEdge,
  Job_OfferConnectionEdge,
  Layout as LayoutType,
  PageBody,
  ServiceConnectionEdge,
  TeamRoles,
} from 'types/graphql'
import { DataService } from 'utils/data'
import { MainSiteLinks, NodeUid } from 'types/util'
import { saveToLocalStorage } from 'utils/localStorage'
import { useRouter } from 'next/dist/client/router'
interface Props {
  preview?: boolean
  lang: string
  title: string
  description: string
  footerType: string
  slices: PageBody[]
  layout: LayoutType
  team: TeamRoles[]
  services: ServiceConnectionEdge[]
  jobOffers: Job_OfferConnectionEdge[]
  links: MainSiteLinks
  caseStudies: Case_StudyConnectionEdge[]
}

const Page = (props: Props) => {
  const router = useRouter()

  useEffect(() => {
    if (router.query.page?.[0] === 'savelives') saveToLocalStorage('recruitee_tag', 'savelives')
  }, [router.query.page])

  return (
    <Layout
      preview={props.preview}
      lang={props.lang}
      title={props.title}
      description={props.description}
      footerType={props.footerType}
      data={props.layout}
      links={props.links}
    >
      <SliceZone
        slices={props.slices}
        team={props.team}
        services={props.services}
        jobOffers={props.jobOffers}
        exitLabel={props.layout.exit_label as string}
        skillsLabel={props.layout.skills_label as string}
        caseStudies={props.caseStudies}
      />
    </Layout>
  )
}

export async function getStaticPaths() {
  // get all paths we want to pre-render
  const data = await fetchAPI(GetPagePaths)
  const paths = data.allPages.edges.map(
    ({
      node: {
        _meta: { uid },
      },
    }: NodeUid) => ({
      params: { page: [uid === 'index' ? '' : uid], lang: 'en' },
    })
  )
  return { paths, fallback: false }
}

export async function getStaticProps({
  params,
  previewData = {},
  preview = false,
}: GetServerSidePropsContext) {
  // use the page url as uid for fetching the slices, default to "index" if its "/"
  const uid = params?.page ? params.page[0] : 'index'
  const data = new DataService(previewData, uid, params)

  const {
    title,
    description,
    footerType,
    slices,
    layout,
    layoutV2,
    team,
    services,
    jobOffers,
    lang,
    links,
    caseStudies,
  } = await data.getProps()

  return {
    props: {
      title,
      description,
      footerType,
      slices,
      layout,
      layoutV2,
      team,
      services,
      jobOffers,
      lang,
      links,
      caseStudies,
      preview,
    },
    revalidate: 1,
  }
}

export default Page
